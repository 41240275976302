import {Component, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap";
import {Request} from "app/services/models/request.model";
import * as _ from 'lodash'

@Component({
    selector: 'app-result-modal',
    templateUrl: './result-modal.component.html',
    styleUrls: ['./result-modal.component.scss']
})
export class ResultModalComponent implements OnInit {
    public request = new Request();
    public edit: boolean = false;
    public requestSaved = _.noop;

    constructor(private modal: BsModalRef) {
    }

    ngOnInit() {
    }

}
